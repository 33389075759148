import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchData, ApiResponse } from "../services/api";

// Slugify helper
const slugify = (text: string) =>
  text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");

interface PackageSlider {
  packageId: string;
  title: string;
  subText: string;
  image: string;
  facilities: string;
  buttonText: string;
  buttonLink: string;
  country: string;
}

const Packages: React.FC = () => {
  const [packages, setPackages] = useState<PackageSlider[]>([]);

  useEffect(() => {
    const fetchPackagesData = async () => {
      try {
        const response: ApiResponse<{
          package_sliders: PackageSlider[];
        }> | null = await fetchData<{ package_sliders: PackageSlider[] }>({
          url: "pages/get",
          data: { pageId: 1 },
        });

        if (response?.data?.package_sliders?.length) {
          setPackages(response.data.package_sliders);
        }
      } catch (error) {
        console.warn("Error fetching packages data. Please try again later.");
      }
    };

    fetchPackagesData();
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2, arrows: false } },
      { breakpoint: 600, settings: { slidesToShow: 1, arrows: false } },
    ],
  };

  if (packages.length === 0) return null;

  return (
    <section className="bg-gradient-to-b from-[#01316E] via-[#737373] to-[#01316E] text-white p-3 sm:p-10 lg:h-[670px]">
      <div className="container mx-auto">
        <h1 className="text-xl lg:text-3xl font-akshar font-bold uppercase text-center mb-4">
          Discover Bespoke Journeys
        </h1>
        <Slider {...sliderSettings}>
          {packages.map((pkg) => (
            <div key={pkg.packageId} className="px-0 lg:px-4">
              <div className="relative rounded-lg overflow-hidden shadow-lg h-[500px]">
                <img
                  src={pkg.image}
                  onError={(e) => (e.currentTarget.src = "/fallback-image.jpg")}
                  alt={pkg.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black to-transparent opacity-50"></div>
                <div className="absolute bottom-4 left-4 right-4 text-white">
                  <h3 className="text-base sm:text-sm font-medium tracking-wide">
                    {pkg.title}
                  </h3>
                  <p className="text-xs">{pkg.subText}</p>

                  {/* Slug-based Button */}
                  {pkg.buttonText && (
                    <button
                      onClick={() => {
                        const slug = slugify(pkg.country);
                        window.location.href = `/country/${slug}/`;
                      }}
                      className="inline-block mt-2 uppercase border border-white text-white py-1 px-2 text-sm font-semibold rounded hover:bg-white hover:text-black transition duration-300"
                    >
                      {pkg.buttonText}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>  
  );
};

export default Packages;
